.background {
  position: relative;
  padding: 0 !important;

  .backgroundImage {
    position: relative;
    width: 100%;
  }
  .textbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 10% 10% 0 10%;
    align-items: center;
    color: var(--light-text);
    background: var(--overlay);
    text-align: center;
    .error {
      font-size: var(--font-size-xxl);
      margin: 0;
    }
    .heading {
      color: var(--light-text);
    }
    .description {
      color: var(--light-text);
    }
    .button {
      margin-top: var(--spacing-md);
      width: clamp(180px, 50%, 300px);
    }
  }
}
