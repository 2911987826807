.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // min-height: 96px;
  margin: auto;
  overflow: hidden;
  animation: fadeIn 0.5s ease-in-out;

  .globe {
    position: relative;
    width: 120px;
    height: 140px;
    margin-bottom: var(--spacing-lg);
    background-image: radial-gradient(
        circle 30px,
        var(--dark-background) 100%,
        transparent 0
      ),
      radial-gradient(circle 5px, var(--dark-background) 100%, transparent 0),
      radial-gradient(circle 5px, var(--dark-background) 100%, transparent 0),
      linear-gradient(var(--dark-background) 20px, transparent 0);
    background-position:
      center 127px,
      94px 102px,
      16px 18px,
      center 114px;
    background-size:
      60px 60px,
      10px 10px,
      10px 10px,
      4px 14px;
    background-repeat: no-repeat;
    z-index: 10;
    perspective: 500px;
    animation: fadeIn 5s linear infinite alternate;

    &::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 3px solid var(--dark-background);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -55%) rotate(-45deg);
      border-right-color: transparent;
      box-sizing: border-box;
    }
    &:after {
      content: "";
      position: absolute;
      height: 80px;
      width: 80px;
      transform: translate(-50%, -55%) rotate(-45deg) rotateY(0deg);
      left: 50%;
      top: 50%;
      box-sizing: border-box;
      border: 7px solid var(--accent-1);
      border-radius: 50%;
      animation: rotate 1s linear infinite;
    }
  }

  @keyframes rotate {
    to {
      transform: translate(-50%, -55%) rotate(-45deg) rotateY(360deg);
    }
  }

  .line {
    align-self: flex-start;
    width: 0;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: linear-gradient(
      to right,

      var(--dark-background) 10%,
      var(--accent-1) 40%,
      transparent
    );
    // box-shadow: 2px 0 5px var(--accent-1-translucent);
    box-sizing: border-box;
    animation: animFw 5s linear infinite;

    &::after,
    &::before {
      content: "";
      width: 10px;
      height: 1px;
      background: var(--dark-background);
      position: absolute;
      top: 9px;
      right: -2px;
      opacity: 0;
      transform: rotate(-45deg) translateX(0px);
      box-sizing: border-box;
      animation: coli1 0.3s linear infinite;
    }
    &::before {
      top: -4px;
      transform: rotate(45deg);
      animation: coli2 0.3s linear infinite;
    }

    @keyframes animFw {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    @keyframes coli1 {
      0% {
        transform: rotate(-45deg) translateX(0px);
        opacity: 0.7;
      }
      100% {
        transform: rotate(-45deg) translateX(-45px);
        opacity: 0;
      }
    }

    @keyframes coli2 {
      0% {
        transform: rotate(45deg) translateX(0px);
        opacity: 1;
      }
      100% {
        transform: rotate(45deg) translateX(-45px);
        opacity: 0.7;
      }
    }
  }

  .donutFill {
    border-radius: 50%;
    position: relative;
    margin-top: 1%;
    transform: rotate(45deg);
    box-sizing: border-box;

    &::before {
      content: "";
      position: absolute;
      box-sizing: border-box;
      border-radius: 50%;
      animation: prixClipFix 2s infinite linear;
    }

    &.mediumSize {
      // border: 10px solid var(--light-background);
      width: 68px;
      height: 68px;
      &::before {
        width: 48px;
        height: 48px;
        inset: 0px;
        border: 10px solid var(--accent-1);
      }
    }

    &.smallSize {
      border: 11px solid var(--light-background);
      &::before {
        width: 24px;
        height: 24px;
        inset: -12.5px;
        border: 5px solid var(--accent-1);
      }
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.fullLoadingContainer {
  width: 100%;
  height: fit-content;
}

/* HTML: <div class="loader"></div> */
.progressBar {
  position: relative;
  display: block;
  width: 100%;
  height: 16px;
  background: linear-gradient(#000 0 0) 0/0% no-repeat #ddd;
  animation-name: progressForward;
  animation-duration: 10s;
  animation-timing-function: linear;
  // animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  &.stepped {
    animation-name: progressForwardStepped;
  }
}

@keyframes progressForward {
  100% {
    background-size: 100%;
  }
}

@keyframes progressForwardStepped {
  0% {
    background-size: 0%;
  }
  25% {
    background-size: 25%;
  }
  35% {
    background-size: 25%;
  }
  50% {
    background-size: 50%;
  }
  65% {
    background-size: 50%;
  }
  75% {
    background-size: 75%;
  }
  90% {
    background-size: 75%;
  }
  100% {
    background-size: 100%;
  }
}
